import Vue from 'vue'

export default{
	namespaced: true,
	state:{
		loading:[],
		listado:[],
		seguimiento:[],
		historial:[],
		search:'',
		// posicionamiento:0
	},

	mutations:{
		LISTADO_PRODUCTOS(state, payload){
      state.listado = payload
		},
		LOADING(state, payload){
			state.loading = payload
		},

		SEGUIMIENTO_RUTAS_FAMILIA(state, payload){
			console.log(payload)
			let seguimiento = [ 
				{ text: payload.familia, 					  disabled: false , href:'', modo: 1, idfamilias: payload.idfamilias },
			];

			state.seguimiento = seguimiento;
		},		
		SEGUIMIENTO_RUTAS_CATEGORIA(state, payload){
			console.log(payload)
			let seguimiento = [ 
				{ text: payload.familia, 					  disabled: false , href:'', idcategorias: payload, modo: 1, idfamilias: payload.idfamilias },
				{ text: payload.categoria.categoria,disabled: false ,	href:'', idcategorias: payload, modo: 2, idfamilias: payload.idfamilias 	},
			];

			state.seguimiento = seguimiento;
		},
		SEGUIMIENTO_RUTAS_SUBCATEGORIA(state, payload){
			console.log(payload)
			let seguimiento = [ 
				{ text: payload.familia, 								   disabled: false, href:'', idsubcategorias: payload, modo: 1, idfamilias: payload.idfamilias },
				{ text: payload.categoria.categoria,			 disabled: false,	href:'', idsubcategorias: payload, modo: 2, idfamilias: payload.idfamilias	},
				{ text: payload.subcategoria.subcategoria, disabled: false,	href:'', idsubcategorias: payload, modo: 3, idfamilias: payload.idfamilias },
			];
			state.seguimiento = seguimiento;
		},

		HISTORIAL_PRODUCTOS(state, payload){
			state.historial.push(payload);
		},

		SEARCH(state, value){
			state.search = value
		},
			// state.posicionamiento = state.historial.length -1;}
	},

	actions:{

		buscar_productos_familia({commit}, payload){
			// console.log('payload cat', payload.categoria.idcategorias );
      commit('LISTADO_PRODUCTOS',[]); commit('LOADING',true); 
			return new Promise((resolve, reject) => {
			  Vue.http.get('tienda/articulos.fam/' + payload.idfamilias).then(response=>{
				// Vue.http.get('obtener.articulos.random').then(response=>{
					commit('LISTADO_PRODUCTOS', response.body);
					commit('SEGUIMIENTO_RUTAS_FAMILIA', payload);
					resolve(response.body) 
			  }).catch(error =>{
					console.log('errror', error)
					reject(false);
				}).finally(()=> { commit('LOADING',false);  })
			})
		},
		
		buscar_productos_categoria({commit}, payload){
			// console.log('payload cat', payload.categoria.idcategorias );
      commit('LISTADO_PRODUCTOS',[]); commit('LOADING',true); 
			return new Promise((resolve, reject) => {
			  Vue.http.get('tienda/articulos.cat/' + payload.categoria.idcategorias).then(response=>{
				// Vue.http.get('obtener.articulos.random').then(response=>{
					commit('LISTADO_PRODUCTOS', response.body);
					commit('SEGUIMIENTO_RUTAS_CATEGORIA', payload);
					resolve(response.body) 
			  }).catch(error =>{
					console.log('errror', error)
					reject(false);
				}).finally(()=> { commit('LOADING',false);  })
			})
		},

    buscar_productos_subcategorias({commit}, payload){
    	console.log('sub',payload)
			// console.log('payload sub', payload.subcategoria.idsubcategorias );
      commit('LISTADO_PRODUCTOS',[]); commit('LOADING',true); 
			return new Promise((resolve, reject) => {
			  Vue.http.get('tienda/articulos.sub/' + payload.subcategoria.idsubcategorias).then(response=>{
				// Vue.http.get('obtener.articulos.random').then(response=>{
					commit('LISTADO_PRODUCTOS', response.body);
					commit('SEGUIMIENTO_RUTAS_SUBCATEGORIA', payload);
					resolve(response.body) 
			  }).catch(error =>{
					console.log('errror', error)
					reject(false);
				}).finally(()=> { commit('LOADING',false);  })
			})
		},

		guardar_historial_productos({commit}, payload) {
			commit('HISTORIAL_PRODUCTOS', payload);
		},

		buscar({commit, dispatch}, value){
			commit('SEARCH',value)
		}
		
	},

	getters:{
		Loading(state){
		  return state.loading
		},

		listado_productos(state){
		  return state.listado
		},

		seguimiento(state){
			return state.seguimiento
		},
		historial_productos(state){
			return state.historial;
		},
		posicionamiento(state){
			return state.posicionamiento;
		},
		getSearch(state){
			return state.search
		}
		
	}
}