import Vue from 'vue'

export default{
	namespaced: true,
	state:{
		direcciones_envios:[],
		direcciones_facturacion:[],
		direccion_activa  : null, // type object
    facturacion_activa: null, // type object
		facturacion_local : null, // type object
		envio_local       : null  // type object
 	},

	mutations:{
		DIRECCIONES_ENVIOS(state, payload){
      state.direcciones_envios = payload
		},
		DIRECCIONES_FACTURACION(state, payload){
			state.direcciones_facturacion = payload
		},
		DIRECCION_ACTIVA(state, payload){
			// console.log('PAYLOAD', payload)
			state.direccion_activa = payload 
		},
		FACTURACION_ACTIVA(state, payload){
			state.facturacion_activa = payload
		},
		DIRECCION_FACTURACION_LOCAL(state, payload){
			state.facturacion_local = payload
		},
		DIRECCION_ENVIO_LOCAL(state, payload){
			state.envio_local = payload
		},
		
	},

	actions:{
		// Valida si el usario existe en la BD
		obtener_direcciones_envio({commit}, idcliente){
			return new Promise((resolve, reject) => {
			  Vue.http.get('direcciones.cliente/' + idcliente).then(response=>{
					commit('DIRECCIONES_ENVIOS', response.body);
					resolve(response.body) 
			  }).catch(error =>{
					console.log('error', error)
					reject(false);
				})
			})
		},

		obtener_direcciones_facturacion({commit}, idcliente){
			return new Promise((resolve, reject) => {
			  Vue.http.get('direcciones.cliente.facturacion/' + idcliente).then(response=>{
					commit('DIRECCIONES_FACTURACION', response.body);
					resolve(response.body) 
			  }).catch(error =>{
					console.log('error', error)
					reject(false);
				})
			})
		},

		obtener_direccion_envio_activa({commit}, idcliente){
			if(idcliente != undefined){
				return new Promise((resolve, reject) => {
				  Vue.http.get('direccion.envio.activa/' + idcliente).then(response=>{
						const payload = new Object();
						if(response.body.length ){
							payload.iddireccion = response.body[0].iddireccion;
							payload.idcliente   = response.body[0].idcliente;
							payload.nombre      = response.body[0].nombre;
							payload.apellido    = response.body[0].apellido;
							payload.estado      = response.body[0].estado;
						  payload.municipio   = response.body[0].municipio;
							payload.colonia     = response.body[0].colonia;
							payload.calle       = response.body[0].calle;
	  				  payload.numero		  = response.body[0].numero;
							payload.cp          = response.body[0].cp;
							payload.telefono    = response.body[0].telefono;
							payload.email       = response.body[0].email;
							payload.activo      = response.body[0].activo;
							commit('DIRECCION_ACTIVA', payload);
						}else{
							commit('DIRECCION_ACTIVA', null);
						}
						resolve(true) 
				  }).catch(error =>{
						console.log('error', error)
						reject(false);
					})
				})
			}
		},

		obtener_direccion_facturacion_activa({commit}, idcliente){
			// return new Promise((resolve, reject) => {
				if(idcliente != undefined){

				  Vue.http.get('direccion.facturacion.activa/' + idcliente).then(response=>{
						const payload = new Object();
						
						if(response.body.length){
 							payload.idfacturacion  = response.body[0].idfacturacion;
 							payload.idcliente      = response.body[0].idcliente;
 						  payload.nombre				 = response.body[0].nombre;
 							payload.apellido       = response.body[0].apellido;
 							payload.estado         = response.body[0].estado;
 							payload.municipio      = response.body[0].municipio; 
 							payload.colonia        = response.body[0].colonia;
 							payload.calle          = response.body[0].calle;
 							payload.numero         = response.body[0].numero;
 							payload.cp             = response.body[0].cp;
 							payload.email          = response.body[0].email;
 							payload.telefono       = response.body[0].telefono;
  						payload.activo         = response.body[0].activo;
							commit('FACTURACION_ACTIVA', payload);
						}else{
							commit('FACTURACION_ACTIVA', null);
						}
						
						resolve(true) 
				  }).catch(error =>{
						console.log('error', error)
						// reject(false);
					})
				}
			// })
		},

		guardar_direccion_envio_local({commit}, payload){
			return new Promise((resolve, reject) => {
				commit('DIRECCION_ENVIO_LOCAL', payload);
				resolve("Dirección de envio guardada correctamente.")
			});
		},

		guardar_direccion_facturacion_local({commit}, payload){
			return new Promise((resolve, reject) => {
				commit('DIRECCION_FACTURACION_LOCAL', payload);
				resolve("Dirección de facturación guardada correctamente.")
			});
		}
	},

	getters:{
		direccionesEnvio(state){
		  return state.direcciones_envios
		},

		direccionesFacturacion(state){
		  return state.direcciones_facturacion
		},

		direccionActiva(state){
			return state.direccion_activa 
		},

		facturacionActiva(state){
			return state.facturacion_activa
		},
		envioLocal(state){
			return state.envio_local;
		},
		facturacionLocal(state){
			return state.facturacion_local;
		}
		
	}
}