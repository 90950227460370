<template>
  <v-container fluid class="pa-4 ">
    <v-row justify="center">

      <v-dialog v-model="FINALIZACION_PEDIDO" persistent width="700px">
        <v-card class="pa-4">
          <v-card-text class="text-center font-weight-black text-h5 mt-3">
            ¡Tu pedido se procesó correctamente!
          </v-card-text>
          <v-card class="text-center" flat>
            <v-expand-x-transition>
              <v-icon class="shrink" style="font-size:130px" color="success"> mdi-check-circle-outline</v-icon>
            </v-expand-x-transition>
          </v-card>
          <!--
          <v-card class="text-center pa-4 my-8 " outlined>
            <span class="font-weight-black text-h6 info--text"> ESTE ES TU NÚMERO DE PEDIDO </span><br> 
            <span class="text-h6"> {{ data_pedido.pedido }} </span>
          </v-card>
          
          <v-card class="text-center pa-4 mt-5 " flat>
            <v-btn text color="secondary" target="_blank" :href="data_pedido.rastreo" class="font-weight-bold subtitle-1"> 
              Rastrea tu pedido desde aquí <v-icon class="text-decoration-underline mx-3"right large> mdi-truck-fast-outline </v-icon>
            </v-btn>
          </v-card>
          -->
          <div class="mt-12"></div>
          <v-footer absolute color="white" class="pa-2" >
            <v-spacer></v-spacer>
            <v-btn dark block  color="warning" :to="{ name: 'Home' }"> Seguir comprando  </v-btn>
          </v-footer>	
        </v-card>
      </v-dialog>

       <v-dialog v-model="ERROR_PEDIDO" persistent width="500px">
        <v-card class="pa-2">
          <v-card-text class="text-center font-weight-black text-h5 mt-3 ">
            Lo sentimos, este pedido ya se ha generado anteriormente. 
          </v-card-text>
          <v-card class="text-center" flat>
            <v-expand-x-transition>
              <v-icon class="shrink" style="font-size:130px" color="error"> mdi-close-circle-outline</v-icon>
            </v-expand-x-transition>
          </v-card>
         
          <div class="mt-12"></div>
          <v-footer absolute color="white" class="pa-2 " >
            <v-spacer></v-spacer>
            <v-btn dark block  color="warning" :to="{ name: 'Home' }"> Regresar al inicio  </v-btn>
          </v-footer>	
        </v-card>
      </v-dialog>



      
		  <overlay v-if="overlay"/>

    </v-row>
  </v-container>
</template>

<script>

  import  metodos from '@/mixins/metodos.js';
	import {mapGetters, mapActions}    from 'vuex'
  import overlay                     from '@/components/overlay.vue'
  import controlDireccionEnvio       from '@/views/Cliente/control_direccion_envio.vue'
  import controlDireccionFacturacion from '@/views/Cliente/control_direccion_facturacion.vue'
  import Vue from 'vue'
	var accounting = require("accounting");

  const mp = new MercadoPago('TEST-5a054ef7-cd86-4517-9841-5ede8fb6705e', {
    // locale: 'es-AR'
  });


  Vue.filter('currency', (val, dec) => { return accounting.formatMoney(val, '$', dec) });
  
  export default {
    mixins:[metodos],
    components:{
      // loading,
      overlay,
      controlDireccionEnvio,
      controlDireccionFacturacion
    },
    data(){
      return{
        ERROR_PEDIDO: false,
        FINALIZACION_PEDIDO: false,
        data_pedido: {},
        data_error : {},
        envio: null,
        idPago:'',
        datosPedido: null,

        banderaPagoExiste:'',

        Loading: false,
        overlay: true,

      }
    },
  
    computed: {
      ...mapGetters('login'  ,['getusuarioFetish','CIAWS']),
      ...mapGetters('Carrito',['getCarrito']),
    },

    async created () {
      var response = window.location;
      //Convertimos en array las variables en el GET
      var pago = {};
      var arreglos = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        pago[key] = value;
      });


      if(pago.status == 'approved'){
        // Pago aprovado
        // Paso #1, validar si ya existe ese pago registrado
        let pagoExiste = await this.validarExistePago(pago.collection_id)
        if(this.banderaPagoExiste.length == 0){
          // El pedido no existe, hay que agregarlo, agregar el pago de mercado pago y actualizar el estatus del movim
          // En este punto el pago ya se acepto ya hay que descontar del almácen los artículos
          await this.addPagoMercadopago(pago)
          // await this.generarEnvio()
          await this.updateEstatusPedido(pago) // Actualizar el movim
          // y ahora siiii eliminar lo del carrito
          // y mostrar alerta de pedido realizado correctamente
        }else{
          // Agregar alerta de que ya se realizo el pago
          this.alerta_de_proceso_pedido()
        }

        let generacion_envio = await this.generarEnvio(pago.preference_id);
        if(!generacion_envio.estatus){
          this.overlay = false;
          // this.data_error = new Object({ pedido: pago.preference_id, rastreo: generacion_envio.data.trackUrl });
          this.ERROR_PEDIDO = true;
        }else if(generacion_envio.estatus){
          this.overlay = false;
          this.limpiarCarrito();
          // this.data_pedido = new Object({ pedido: pago.preference_id, rastreo: generacion_envio.data.trackUrl });
          this.FINALIZACION_PEDIDO = true;
        }
      }else{
        // Pago no aprobado, favor de generar un dialogo y eliminar datos registrados
        await this.eliminarTodo()
      }
    },

    methods: {
      ...mapActions('Direcciones',['obtener_direcciones_envio',
                                   'obtener_direccion_envio_activa',
                                   'obtener_direccion_facturacion_activa',
                                   'obtener_direcciones_facturacion']),
      ...mapActions('Carrito',['limpiarCarrito']),

                                   

      validarExistePago (collection_id) {
        return this.$http.get('mercadopago.validarpago/' + collection_id).then(response=>{
          this.banderaPagoExiste = response.body
        }).catch(error=>{
          console.log(error)
        })
      },

      addPagoMercadopago (pago) {
        return this.$http.post('mercadopago.add.pedido',pago).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },

      updateEstatusPedido (pago) {
        return this.$http.put('mercadopago.update/' + pago.preference_id).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },

      eliminarTodo () {
        // esta función no esta terminada no elimina nada, pero mañana lo hago 21/09/2021
        return this.$http.post('mercadopago.eliminar.pedido',this.datosPedido).then(response=>{
          // console.log('se agrego el pago de mercadopago',response.body)
        }).catch(error=>{
          console.log(error)
        })
      },
    },
  }
</script>
