<template>
	<v-container fluid>
		<v-row justify="center" >
			<v-col cols="12" >
        
				<v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
					<strong> {{alerta.texto}} </strong> 
					<template v-slot:action="{ attrs }">
						<v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
					</template>
				</v-snackbar>

        <v-toolbar flat>
          <div class="font-weight-black text-md-h6 text-xs-subtitle-1 " v-if="modo == 1"> NUEVA DIRECCIÓN DE ENVÍO  </div>
          <div class="font-weight-black text-md-h6 text-xs-subtitle-1 " v-if="modo == 2"> EDITAR DIRECCIÓN DE ENVÍO </div>
          <div class="font-weight-black text-md-h6 text-xs-subtitle-1 " v-if="modo == 3"> DIRECCIÓN DE ENVÍO  </div>
          <v-spacer></v-spacer>
          <v-btn color="error"  @click="$emit('modal',false)" ><v-icon>clear</v-icon></v-btn>
        </v-toolbar>
         
				<v-row class="mt-3">
					<v-col cols="12" sm="6">
						<v-text-field
							label="Nombres"
							placeholder="Nombres"
							hide-details
							clearable
							v-model ="datosDireccion.nombre"
							filled 
						></v-text-field>
					</v-col>

          <v-col cols="12" sm="6" >
						<v-text-field
							label="Apellidos"
							placeholder="Apellidos"
							hide-details
							clearable
							v-model ="datosDireccion.apellido"
							filled 
						></v-text-field>
					</v-col>

          <v-col cols="9" sm="4" >
						<v-text-field
							label="Codigo Postal"
							placeholder="Codigo Postal "
							hide-details
							clearable
							v-model ="datosDireccion.cp"
							filled 
						></v-text-field>
					</v-col>

					<v-col cols="2" class="text-left">
						<v-btn 
							color="info" :disabled="datosDireccion.cp? false: true" 
							@click="ciudades_por_codigo_postal(datosDireccion.cp)" 
						>
							<v-icon >search</v-icon>
						</v-btn>
					</v-col>

					<!--
					<v-col cols="12" sm="6" >
						<v-text-field
							label="Municipio"
							placeholder="Municipio"
							hide-details
							clearable
							v-model ="datosDireccion.municipio"
							filled 
              
							disabled
						></v-text-field>
					</v-col>
					-->

					<v-col cols="12" sm="6" >
            <v-autocomplete
              :items="colonias" v-model="datosDireccion.colonia" item-text="nombre" item-value="id" label="Colonia" 
              dense filled hide-details clearable :disabled="colonias.length? false: true"
            ></v-autocomplete>
          </v-col>

					<v-col cols="12" sm="6" >
						<v-text-field
							label="Calle"
							placeholder="Calle "
							hide-details
							clearable
							v-model ="datosDireccion.calle"
							filled 
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6" >
						<v-text-field
							label="Número"
							placeholder="Número "
							hide-details
							clearable
							v-model ="datosDireccion.numero"
							filled 
						></v-text-field>
					</v-col>

					<!--
					<v-col cols="12" >
            <v-autocomplete
              :items="ciudades" v-model="datosDireccion.ciudad" item-text="nombre" item-value="id" label="Ciudad" 
              dense filled hide-details return-object clearable
            ></v-autocomplete>
          </v-col>
					-->

          <v-col cols="12" sm="6" >
						<v-text-field
							label="Telefono"
							placeholder="Telefono"
							hide-details
							clearable
							v-model="datosDireccion.telefono"
							filled 
              type="number"
						></v-text-field>
					</v-col>

					<v-col cols="12" sm="6" >
						<v-text-field
							label="Correo electronico"
							placeholder="Correo electronico"
							hide-details
							clearable
							v-model="datosDireccion.email"
							filled 
						></v-text-field>
					</v-col>
				</v-row>
        
				<!-- //DIALOG PARA GUARDAR LA INFORMACION -->
				<div class="mt-5"></div>
				<v-card-actions class="pa-0" >		
					<v-spacer></v-spacer>
					 <v-btn  :disabled="overlay" persistent :loading="overlay" dark large color="secondary" @click="validaInfo">
             <!-- {{ modo === 1 ? 'Guardar Información': 'Actualizar Información'}} -->
						 <span v-if="modo == 1"> GUARDAR INFORMACIÓN    </span>
						 <span v-if="modo == 2"> ACTUALIZAR INFORMACIÓN </span>
						 <span v-if="modo == 3"> CALCULAR ENVÍO         </span>
          </v-btn>
				</v-card-actions>

			</v-col>
		</v-row>
		<overlay v-if="overlay"/>
	</v-container>
</template>

<script>
	import  metodos from '@/mixins/metodos.js';
	import {mapGetters, mapActions} from 'vuex'
	import overlay from '@/components/overlay.vue'
  const axios = require('axios');
	
	export default {
		mixins:[metodos],
	  components: {
			overlay,
		},
		props:[
			'parametros',
			'modo',
	  ],
	  data () {
			return {
        datosDireccion:{},
        ObjectDefault:{
          nombre: '',
          apellido: '',
          municipio: '',
					calle: '',
          numero:'',
          colonia: '' ,
          cp:'',
          telefono:null,
          email: '',
					municipio:'',
					estado: '',
        },

				colonias: [],
				// ALERTAS
        alerta: { activo: false, texto:'', color:'error', vertical:true },
				overlay    : false,
				correcto   : true,
				// BOTON DE BORRAR
				borrarModal: false,
        usuarioFetish: [],
			}
		},
		
		created(){
      this.validaModoVista();
			// this.consulta_ciudades();
		},
			
		computed:{
			// IMPORTANDO USO DE VUEX - CLIENTES (GETTERS)
      ...mapGetters('login',['getusuarioFetish']),
      ...mapGetters('Direcciones',['envioLocal','direccionesEnvio']),

		},
		watch:{
      modo(){
        this.validaModoVista();
      },
			parametros(){
				this.validaModoVista();
			},
			getusuarioFetish(){
				this.validaModoVista();
			}
    },

		methods:{
      ...mapActions('Direcciones',['obtener_direcciones_envio',
																	 'guardar_direccion_envio_local',
																	 'obtener_direccion_envio_activa']),
      
      validaModoVista(){
        if(this.modo === 1 ){
          this.datosDireccion = this.ObjectDefault
        }else if(this.modo === 2){
					this.datosDireccion = new Object({
						iddireccion  : this.parametros.iddireccion,
						nombre       : this.parametros.nombre,
						apellido     : this.parametros.apellido,
						cp				   : this.parametros.cp,
						municipio    : this.parametros.municipio,
						colonia      : this.parametros.colonia ,
						calle        : this.parametros.calle,
						numero  		 : this.parametros.numero,
						telefono     : this.parametros.telefono,
						email        : this.parametros.email,
						activo       : this.parametros.activo
					});
					this.ciudades_por_codigo_postal(this.datosDireccion.cp); 

          // this.datosDireccion = this.parametros
        }else if(this.modo === 3){
					if(this.getusuarioFetish){ // EVALUO SI HAY UN USUARIO ACTIVO
          	this.datosDireccion = this.ObjectDefault
					}else{ 
						this.datosDireccion = this.envioLocal ? this.envioLocal.data : this.ObjectDefault // OBTENGO LOS DATOS DE LA DIRECCION LOCAL
					}
				}
      },

			validaInfo(){
				if(!this.datosDireccion.nombre)	  { this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL NOMBRE '           , color:'error'}; return };
				if(!this.datosDireccion.apellido)	{ this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL APELLIDO'          , color:'error'}; return };
				if(!this.datosDireccion.cp)	      { this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL CODIGO POSTAL'     , color:'error'}; return };
				if(!this.datosDireccion.municipio){ this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL MUNICIPIO'         , color:'error'}; return };
				if(!this.datosDireccion.colonia)  { this.alerta={ activo: true, texto:'NO PUEDES OMITIR LA COLONIA'           , color:'error'}; return };
				if(!this.datosDireccion.calle)    { this.alerta={ activo: true, texto:'NO PUEDES OMITIR LA CALLE'             , color:'error'}; return };
				if(!this.datosDireccion.numero)   { this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL NÚMERO'            , color:'error'}; return };
				if(!this.datosDireccion.telefono) { this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL TELEFONO'          , color:'error'}; return };
				if(!this.datosDireccion.email)    { this.alerta={ activo: true, texto:'NO PUEDES OMITIR EL CORREO ELECTRONICO', color:'error'}; return };
        this.preparar_objeto()
			},

      preparar_objeto(){
        const payload = new Object({
          idcliente: this.getusuarioFetish.idcliente,
          data     : this.datosDireccion,
					activo   : !this.direccionesEnvio.length ? 1:0 ,
        });

				if(this.modo === 1){
					this.crear_nueva_direccion(payload);
				}else if(this.modo === 2){
					this.actualizar_direccion(payload);
				}else if(this.modo === 3){
					if( this.getusuarioFetish){
						this.crear_nueva_direccion_activa(payload);
					}else{
						this.guardar_datos_local(payload)
					}
				}

      },

      crear_nueva_direccion(payload){
        this.overlay = true;								
				this.$http.post('agregar.direccion.cliente', payload).then((response)=>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'};
          this.terminar_proceso();
				}).catch(error =>{
					console.log('error',error)
					 this.alerta = { activo: true, texto: error.body.message , color:'error'}
				}).finally(()=>{ this.overlay = false })
      },
			
			actualizar_direccion(payload){
				this.overlay = true;								
				this.$http.post('actualiza.direccion.envio', payload).then((response)=>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'}
          this.terminar_proceso();
				}).catch(error =>{
					console.log('error',error)
					this.alerta = { activo: true, texto: error.body.message , color:'error'}
				}).finally(()=>{ this.overlay = false })
			},

			crear_nueva_direccion_activa(payload){
        this.overlay = true;								
				this.$http.post('agregar.direccion.cliente.activa', payload).then((response)=>{
          this.obtener_direccion_envio_activa(this.getusuarioFetish.idcliente);
					this.obtener_direcciones_envio(this.getusuarioFetish.idcliente);
					this.alerta = { activo: true, texto: response.body.message , color:'success'};
      	  this.datosDireccion = this.ObjectDefault;
      	  let that = this; setTimeout(()=> { that.$emit('modal',false)}, 2000);
				}).catch(error =>{
					console.log('error',error)
					 this.alerta = { activo: true, texto: error.body.message , color:'error'}
				}).finally(()=>{ this.overlay = false })
      },
			

			guardar_datos_local(payload){
				this.guardar_direccion_envio_local(payload).then( response =>{
					this.$emit('direccion',true);
			 		this.alerta = { activo: true, texto: response , color:'success'}
				  let that = this; setTimeout(()=> { that.$emit('modal',false);}, 2000);
				})
			},

      terminar_proceso(){
        var that = this;
				this.obtener_direcciones_envio(this.getusuarioFetish.idcliente);
				// this.calcular_precio_envio();
				this.$emit('direccion',true);
        setTimeout(()=> { that.$emit('modal',false)}, 2000);
        this.datosDireccion = this.ObjectDefault;
      }

			
		}
	}
</script>