import Vue from 'vue'

export default{
	namespaced: true,
	state:{
		login:false,
		usuarioFetish:'',
		pariente: '',
		ciaws: null
	},

	mutations:{
		LOGEADO(state, value){
			state.login = value
		},
		DATOS_USUARIO(state, usuarioFetish){
      state.usuarioFetish = usuarioFetish
		},

		PARIENTE(state, pariente){
			state.pariente = pariente
		},

		SALIR(state){
			state.login        = false
			state.usuarioFetish = ''
			state.pariente     = ''
		},

		GUARDAR_CIAWS(state, payload){
			state.ciaws = payload
		}
	},

	actions:{
		// Valida si el usario existe en la BD
		validarUser({commit}, usuario){
			return new Promise((resolve, reject) => {
			  Vue.http.post('session.cliente', usuario).then(response=>{
					commit('DATOS_USUARIO', response.body);
					console.log('response', response)
					resolve(response.body) 
						// resolve(false) 
			  }).catch(error =>{
					console.log('errror', error)
					reject(false);
				})
			})
		},

		GetInfoUser({commit, dispatch}, usuario){
			return new Promise((resolve, reject) => {
			  Vue.http.post('sessions', usuario).then(response=>{
	        console.log('response',response.body[0])
					if(!response.body.length){
						resolve(false) 
					}else{
						if(response.body[0].estatus == 0){
							resolve('Tienes que validar tu correo');
						}else if(response.body[0].estatus == 1){
							resolve('No tienes acceso lo sentimos');
						}else if(response.body[0].estatus === 4){
							resolve('Esta cuenta se encuentra deshabilitada.');
						}else{
	        		commit('DATOS_USUARIO',response.body[0])
							commit('LOGEADO', true)
							resolve(true)
						}
	      	}
	    	}, error => {
	      	resolve(false)
	    	})
			})
		},

		refreshDatos({commit, dispatch}, usuario){
			commit('DATOS_USUARIO',usuario)
		},

		verPariente({commit}, pariente){
			return new Promise((resolve, reject) => {
    		commit('PARIENTE',pariente)
				resolve(true)
    	}, error => {
      	resolve(false)
    	})
		},

		salirLogin({commit}){
			commit('SALIR');
			localStorage.clear();
		},

		guardar_ciaws({commit}, payload){
			commit('GUARDAR_CIAWS', payload); 
		}


	},

	getters:{
		getLogeado(state){
		  return state.login
		},
		getusuarioFetish(state){
			return state.usuarioFetish
		},

		getPariente(state){
			return state.pariente
		},
		
		CIAWS(state){
			return state.ciaws
		}

	}
}