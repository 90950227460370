import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import router from '@/router'

export default{
	namespaced: true,
	state:{
		carrito:[],
		carritovisible: false,
		tc: '',
		Cerrar_Carrito: false,
		abrir_carrito: false,
	},

	mutations:{
		TC(state, value){
			state.tc = value

		},

		ADDCARRITO(state, productos){
			var bandera = false
			for(const i in state.carrito){
				if(state.carrito[i].nomart == productos.nomart){
					state.carrito[i].cantidad += 1
					bandera = true
				}
			}
			if(!bandera){
				state.carrito.push(productos)
			}

		},
		
		LIMPIAR(state, value){
			state.carrito = []
		},

		CERRARCARRITO(state,value){
			state.Cerrar_Carrito = value
		},

		UPDATECARRITO(state, value){
			state.carrito = value
		}

	},

	actions:{
		abrirCarrito({commit}, estado){
      commit('ABRIR_CARRO',estado)
    },

		carritoadd({commit}, articulo){
			return new Promise((resolve, reject) => {
				commit('ADDCARRITO',articulo)
				resolve(true)
			})
		},

		limpiarCarrito({commit}, value = []){
			return new Promise((resolve, reject) => {
				// Se vacia el carrito
				commit('LIMPIAR', value)
				resolve(true)
			})
		},

		CerrarCarrito({commit} , accion){
			commit('CERRARCARRITO', accion)
		},

		updateCarrito({commit}, carrito){
			return new Promise((resolve, reject) => {
				commit('UPDATECARRITO',carrito)
				resolve(true)
			})
		},
  },

	getters:{
		getCarrito(state){
			return state.carrito
		},

		EstadoCarrito(state){
			return state.Cerrar_Carrito
		},

		getStateCarrito(state){
      return state.abrir_carrito
    }
	}
}