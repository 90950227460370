<template>
	<v-container >
    <v-row>
      <v-carousel cycle height="auto" hide-delimiter-background hide-delimiters show-arrows-on-hover>
        <template v-slot:prev="{ on, attrs }">
          <v-btn icon large color="white" v-bind="attrs" v-on="on" >  <v-icon> mdi-chevron-left </v-icon> </v-btn>  
          </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn icon large color="white" v-bind="attrs" v-on="on"> <v-icon> mdi-chevron-right </v-icon> </v-btn>
        </template>
        <v-carousel-item v-for="(item, i) in cel_anuncios" :key="i" :src="item.imgCel" >
          <v-row class="fill-height" align="end" justify="center">
            <v-col cols="12" class="text-center"> 
              <v-btn 
                color="secondary" 
                rounded elevation="12" @click="irA(item.path)"> 
              <!-- Más Información {{ carruselActivo }} -->
              </v-btn>  
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-container >
</template>

<script>
  export default {
    components: {
    },
    data () {
      return {
        cel_anuncios:
          [{
            id     : 1, 
            imgCel : require('@/assets/anuncios/cel-tendencias.webp'),
            path   : 'tendencias'
          },
          {
            id     : 2, 
            imgCel: require('@/assets/anuncios/cel-regalo.webp'),
            path   : 'el.regalo.perfecto'
          },
          {
            id     : 3, 
            imgCel: require('@/assets/anuncios/cel-juguete.webp'),
            path   : 'mi.primer.juguete'
          },
        ],
      }
    },
    methods:{
      irA(path){
        this.$router.push({ name: path }); 
      }
    }
  }
</script>



