import Vue from 'vue'
import Vuex from 'vuex'
import router  from '@/router'

import Carrito     from '@/modules/carrito';
import login       from '@/modules/login';
import Direcciones from '@/modules/direcciones';
import Listado     from '@/modules/listado_productos';
import  createPersistedState  from  'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    abrir_carrito: false,
  },
  getters: {
  },
  mutations: {
    ABRIR_CARRO(state, estado){
      state.abrir_carrito = estado
    }
  },

  actions: {
    abrirCarrito({commit}, estado){
      commit('ABRIR_CARRO',estado)
    },
  },

  modules: {
    Carrito,
    login,
    Direcciones,
    Listado
  },

  getters:{
    getStateCarrito(state){
      return state.abrir_carrito
    }
  },

  plugins: [createPersistedState()]
})
