<template>
  <v-container fluid>
    <v-snackbar v-model="alert.active" multi-line :vertical="alert.vertical" top right :color="alert.color" > 
      <strong> {{alert.text}} </strong>
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click="alert.active = false" v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>

    <v-card-text class="font-weight-bold text-h6 pa-0">
      REGISTRAR USUARIO
    </v-card-text>
    <v-form ref="form" v-model="valid" class="mt-2">
      <v-row>
        <v-col cols="12" >
          <label>Nombre completo</label>
          <v-text-field
            v-model ="nombre"
            append-icon="person"
            placeholder="Santiago Rodriguez Ramirez"
            outlined
            hide-details
            dense
            :rules="nombreRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" >
          <label >Correo electronico</label>
          <v-text-field
            v-model="email"
            append-icon="email" 
            placeholder="email@example.com" 
            outlined
            hide-details
            dense
            :rules="emailRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" >
          <label > Telefono / Celular</label>
          <v-text-field
            v-model ="telefono"
            append-icon="phone"
            placeholder="00-0000-0000 "
            outlined
            hide-details
            dense
            type="number"
            :rules="phoneRules"
          ></v-text-field>
        </v-col>

        <template >
          <v-col cols="12" >
          <label > Contraseña </label>
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'visibility_off' : 'visibility'"
              :type="show1 ? 'text' : 'password'"
              placeholder="**********"
              outlined
              hide-details
              dense
              @click:append="show1 = !show1"
              :rules="pass1Rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" >
            <label > Confirmar Contraseña </label>
            <v-text-field
              v-model="password2"
              :append-icon="show2 ? 'visibility_off' : 'visibility'"
              :type="show2 ? 'text' : 'password'"
              placeholder="**********"
              outlined
              hide-details
              dense
              @click:append="show2 = !show2"
              :rules="pass2Rules"
            ></v-text-field>
          </v-col>
        </template>

        <v-col cols="6">
          <v-btn block text center color="error" @click="$emit('login',true)" >
              <v-icon left> keyboard_backspace </v-icon> Regresar
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn block :disabled="!valid" persistent :loading="overlay" center dark color="primary" @click="validaPasswords" >
             Registrar <v-icon right> save </v-icon> 
          </v-btn>
        </v-col>
       
      </v-row>
    </v-form>

    <overlay v-if="overlay"/>

  </v-container >
</template>

<script>
	import overlay  from '@/components/overlay.vue'
	var md5 = require('md5');

	export default {
	  components: {
			overlay
		},

	  data () {
			return {
				titleModal: 'Users',

				// VARIABLES PRINCIPALES
				id_usuario:  null,
				nombre		  : '',
				email		  : '',
				last_nombre : '',
				country   : '',
				telefono     : '',
				password			:'',
				password2			:'',

					// VARIABLES PARA CONTRASEÑA
				show1					: false,
				show2					: false,
				
				valid: false,
				nombreRules     : [v => !!v || 'Es requerido'],
				lastnombreRules :  [v => !!v || 'Es requerido'],
				emailRules      : [v => !!v || 'Es requerido', v => /.+@.+\..+/.test(v) || 'E-mail no es valido'],
				countryRules  :  [v => !!v || 'Es requerido'],
				phoneRules    :  [v => !!v || 'Es requerido'],
				pass1Rules    :  [v => !!v || 'Es requerido'],
				pass2Rules    :  [v => !!v || 'Es requerido'],
				
				alert       : { active: false, text:'', color:'error', vertical:true },
				overlay     : false,
				
			}
		},
		
		methods:{
			validaPasswords(){
        if(this.password.localeCompare(this.password2) ){ //  VERIFICO QUE LAS CONTRASEÑAS SEAN IGUALES
          this.alert    = { active: true, text:'Passwords no match', color:'error' }; return;
        }
        this.PreparateDataSend()
			},

			PreparateDataSend(){
        // PREPARO EL OBJETO QUE ENVIARE A INSERTAR
				const payload = new Object();
							payload.nombre    = this.nombre;
							payload.email     = this.email;
							payload.telefono  = this.telefono;
							payload.password  = md5(this.password);

        this.registerUser(payload); // MANDO A REGISTRAR EL USUARIO
			},

			registerUser(payload){
				this.overlay = true; // ACTIVO OVERLAY DE ESPERA.
				this.$http.post('clientes.add', payload).then((response)=>{
				  let that = this ;
          this.alert    = { active: true, text: response.body.message, color:'success' };
          this.limpiarCampos();
          setTimeout(() =>{ that.$emit('login', true)}, 2000);

				}).catch(error =>{
          this.alert    = { active: true, text: error.body.message, color:'error' };
				}).finally( ()=>{ 
          this.overlay = false
        })
			},
			
			limpiarCampos(){
				this.nombre      = '';
				this.email     = '',
        this.telefono     = '',
				this.password  = '',
				this.password2 = '';
			}
		}
	}
</script>