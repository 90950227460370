<template>
  <v-container class="pb-12" fluid>
    <v-row justify="center" class="fill-height" align="center" >
     
      <v-btn color="accent" style="display: none" class="ir-arriba " dark fab fixed bottom right>
        <v-icon top>keyboard_arrow_up</v-icon>
      </v-btn>

      <v-btn fab fixed bottom left   color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>
       <v-col cols="12"  class="white--text font-weight-bold  text-center py-0" >
       <v-card flat color="black" class="font-weight-black py-0" align="center"> 
          <v-img src="@/assets/titulos/fetish.png"  :width="$vuetify.breakpoint.name=== 'xs'? '100%': '65%' "/>
        </v-card>
          <!-- En tiempos de crisis la masturbación es nuestra mejor amiga id="titulo" -->
      </v-col>

      <v-col cols="12" sm="6" md="3" class="white--text text-left " v-for="(item, i) in listado" :key="i">
         <v-card color="primary" align="center">
          <v-img :src="item.foto" width="250px" loading="lazy" />
          <v-card-text class="white--text text-center text-h5 font-weight-black">
            {{ item.nombre}}
          </v-card-text>
         </v-card>
      </v-col>
      <v-col cols="12" class="text-center py-0">
        <v-card-text class="white--text text-h6">
          <ul> Los orgasmos pueden ser calmantes naturales y podrían ayudarte con los dolores menstruales. </ul>
          <ul> 
            Explorar tu cuerpo y aprender sobre aquello que te produce placer sexual puede ser empoderador y ayudarte 
            a mejorar tu imagen corporal.
          </ul>
            <br>
        </v-card-text>
      </v-col>
      
      <v-col cols="12" class="py-0 "> <v-divider class="secondary" ></v-divider> </v-col>

      <v-col cols="12" md="5"  class="text-center ">
        <v-card class="primary" align="center" > 
          <div class="font-weight-black my-5"> 
           <!-- Tengo ganas de mi -->
           <v-img src="@/assets/titulos/Tengo-ganas.png"  :width="$vuetify.breakpoint.name=== 'xs'? '100%': '100%' "/>
          </div>
           <v-img src="@/assets/fetichismo/manuel.webp" width="500px" loading="lazy"  /> 
          <v-card-text class="white--text font-weight-bold text-h5 secondary--text"> LA MEJOR FORMA DE MASTURBARSE </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" class="text-justify"> 
        <!-- <v-card color="primary" flat> -->
          <v-card-text class="white--text subtitle-1">
          <ul> <span class="secondary--text font-weight-black text-h6"> CREA TU AMBIENTE  </span><br>
            El entorno a veces puede hacer una gran diferencia bajar las luces, poner música sensual, servir una copa de vino, encender velas, aromaterapia.
          </ul> <br>
          <ul> <span class="secondary--text font-weight-black text-h6">  PONTE COMODO </span> <br>
            Prueba a masturbarte tumbada, sentada, de pie o apoyada en una pared. Puede que aprendas a disfrutar del sexo desde una posición que antes no te gustaba.
          </ul> <br>
          <ul> <span class="secondary--text font-weight-black text-h6">  RELAJATE </span> <br>
            No tienes que apresurar la masturbación. Juega con las técnicas y tómate tu tiempo para disfrutar todas las sensaciones que tengas en tu cuerpo.
          </ul> <br>
          <ul><span class="secondary--text font-weight-black text-h6">  UTILIZA LUBRICANTE </span> <br>
            La masturbación en seco no es lo ideal. El lubricante se emplea para aumentar el placer y/o evitar el dolor durante la actividad sexual,
          </ul><br>
          <ul> <span class="secondary--text font-weight-black text-h6">  ZONAS EROGENAS </span> <br>
            No te límites a los órganos sexuales, identificar las zonas más erógenas de tu cuerpo es una garantía para una vida sexual plenamente satisfactoria.
          </ul><br>
          <ul> <span class="secondary--text font-weight-black text-h6">  EXPERIMENTA </span> <br>
            Experimentar con diferentes formas de masturbarse puede significar incorporar juguetes sexuales. 
            Los vibradores y consoladores son muy divertidos para jugar, pero no son los únicos juguetes sexuales disponibles. 
            Por ejemplo, a algunas personas les gusta estimularse el clítoris usando una ducha o regadera manual o frotar su vulva contra la almohada.
          </ul><br>
          <ul><span class="secondary--text font-weight-black text-h6">   EROTISMO </span><br>
            Es divertido dejar que tu mente divague, pero no siempre tienes que usar tu imaginación, 
            lee un libro erótico o escucharlo en forma de audiolibro, también puedes mirar vídeos porno.
          </ul>
          </v-card-text>
        <!-- </v-card> -->
      </v-col>
      <v-col cols="12">
        <v-divider class="secondary"></v-divider>
      </v-col>

      <v-col cols="12" md="5"  class="text-center ">
        <v-card class="primary" align="center" > 
          <div class="font-weight-black my-5"> 
           <v-img src="@/assets/titulos/Clitoris.png"  :width="$vuetify.breakpoint.name=== 'xs'? '100%': '100%' "/>
             <!-- Orgasmo del Clítoris -->
          </div>
          <v-img src="@/assets/fetichismo/vagina.webp" width="400px" loading="lazy"  />
          <div class="white--text font-weight-bold text-h5 secondary--text"> LA MEJOR FORMA DE MASTURBARSE </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" class="text-justify"> 
        <!-- <v-card color="primary"> -->
          <v-card-text class="white--text subtitle-1">
          <ul> <span class="secondary--text font-weight-black text-h6">  PLACER SIN DOLOR </span><br>
            El clítoris es un órgano muy sensible. Para estimularlo hay que comenzar con movimientos muy suaves. 
          </ul> <br>
          <ul> <span class="secondary--text font-weight-black text-h6">  ESTIMULACIÓN MANUAL Y ORAL </span><br>
            Los dedos o la lengua son instrumentos ideales para la estimulación del clítoris. 
          </ul> <br>
          <ul> <span class="secondary--text font-weight-black text-h6">  EMPIEZA FROTANDO O ACARICIANDO TU CLÍTORIS </span><br>
            Mueve tus dedos más rápido y con más fuerza una vez tu clítoris se humedezca o después de haber aplicado lubricante. 
            Intensifica la sensación al aplicar presión fuerte hasta que logres el orgasmo. Encuentra un ritmo que te haga sentir bien. 
          </ul> <br>
          <ul> <span class="secondary--text font-weight-black text-h6">  EMPIEZA FROTANDO O ACARICIANDO TU CLÍTORIS </span><br>
            Mueve tus dedos más rápido y con más fuerza una vez tu clítoris se humedezca o después de haber apulcado lubricante. 
            Intensifica la sensación al aplicar presión fuerte hasta que logres el orgasmo. Encuentra un ritmo que te haga sentir bien.
            <br><br> 
            Usar un vibrador es una forma maravillosa de estimular tu clítoris sin tener que esforzar tus dedos. Solo mueve tu juguete sexual hacia arriba y abajo del área hasta que lo logres.
          </ul> 
          </v-card-text>
        <!-- </v-card> -->
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
  import $ from 'jquery'

  export default {
    data:() =>({
      listado:[
        { id:1, nombre:'ALIVIA EL ESTRÉS'     , foto:require('@/assets/fetichismo/BM1.webp') },
        { id:2, nombre:'MEJORA EL SUEÑO'      , foto:require('@/assets/fetichismo/BM2.webp')},
        { id:3, nombre:'MEJOR ESTADO DE ANIMO', foto:require('@/assets/fetichismo/BM3.webp')},
        { id:4, nombre:'PLACER Y BIENESTAR'   , foto:require('@/assets/fetichismo/BM4.webp')},

      ],
    }),

    created(){
      this.init();
    },

    methods:{
      init(){
				$('body, html').animate({ scrollTop: '0px'}, 300);
      }
    },

    mounted(){
			// Jquey para activar la animacion del boton hacia arriba
			$(document).ready(function(){

				$('.ir-arriba').click(function(){
					$('body, html').animate({
						scrollTop: '0px'
					}, 300);
				});
				
				$(window).scroll(function(){
					if( $(this).scrollTop() > 0 ){
						$('.ir-arriba').slideDown(300);
					} else {
						$('.ir-arriba').slideUp(300);
					}
				});
			});
  	},
    
  }
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');


</style>