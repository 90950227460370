<template>
  <v-container fluid>
    <v-row justify="center" class="pa-0">
     
        <v-snackbar v-model="alert.active"  multi-line vertical top right :color="alert.color" > 
          <strong> {{alert.text}} </strong>
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text @click="alert.active = false" v-bind="attrs"> Cerrar </v-btn>
          </template>
        </v-snackbar>

        <v-col cols="12" >
          <!-- <v-toolbar color="primary" dark>
            <v-toolbar-title > Olvide mi contraseña </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn  text color="white" x-small fab dark :to="{name: 'Login'}"><v-icon>reply</v-icon></v-btn>
               <img src="logo.png" width="200" height="80%" class="mb-5" >
          </v-toolbar> -->
         
          <v-form ref="form" v-model="valid" >
            <v-row>
              <v-col cols="12" >
                <label>Correo eléctronico</label>
                <v-text-field
                  v-model="email" 
                  :rules="emailRules"
                  append-icon="email" 
                  placeholder="email@example.com"
                  required
                  outlined
                  dense>
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="py-0 pa-0"> 
            <v-btn text dark color="error" @click="$emit('modal',false)">
              <v-icon left> keyboard_backspace </v-icon> Regresar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid"  dark color="success" @click="ValidarCorreo()">Validar Correo</v-btn>
          </v-card-actions>
            
        </v-col>
    </v-row>
	</v-container>
</template>

<script>
 import {mapActions , mapGetters} from 'vuex'
  export default {
    data(){
      return{
        valid:false,
        email:'',
        random:1,
        letraRandom:'',
        alert: { active: false, text:'', color:'error' },
        color:'yellow darken-2',
         emailRules: [v => !!v || 'Es requerido', v => /.+@.+\..+/.test(v) || 'E-mail no es valido'],
        letras:['A','b','a','z','X','W','v','F','H','s','U']
      }
    },

    created(){
    },

    computed:{
    },

    methods:{

      formarCodigo(){
        var codigo = this.letras[Math.floor(Math.random() * 10)];
        for(let i=0;i<3;i++){
          var numero  = Math.floor(Math.random() * 10);
          var numero2 = Math.floor(Math.random() * 10);
          var letra  = this.letras[Math.floor(Math.random() * 10)]
          codigo = codigo + numero + numero2 + letra
        }
        return codigo;
      },

      ValidarCorreo(){
      	const payload = new Object({  email: this.email }) 
          this.$http.post('olvida.contra.cliente', payload).then(response =>{
          if(!response.body.length){
            this.alert = { active: true, text:"Esté correo no es valido.", color:'error' },
            this.email = '';
          }else{
            const payload = new Object({
              id      : response.body[0].idcliente,
              nombre : response.body[0].nombre,
              email   : response.body[0].email,
              url    : "https://ky-fetish.com/",
              codigo  : this.formarCodigo()
            });
            this.ActualizaPassExtra(payload);
            this.Enviarcorreo(payload)
          }
        }).catch(function(error){console.log('error',error)})	
      },

      ActualizaPassExtra(usuario){
        var md5 = require('md5');
        const payload = { id: usuario.id, codigo: md5(usuario.codigo) }
        this.$http.post('password.extra.cliente',payload).then(response =>{
          // console.log('pass extra', response.body)
        }).catch(err =>{
          console.log('err',err)
        })
      },

      Enviarcorreo (payload){

        
				this.$http.post('send.contra',payload).then(function(response){
          // console.log('Entre a eviar correo')
	        if(response.status == 200){
            this.alert ={ active: true, text:'Se ha enviado el correo de validacion a su cuenta.', color:'success' },
	        	this.to=''
	        	this.subject=''
	        	this.cuerpo=''
	        }else{
            this.alert ={ active: true, text:'Falla al enviar el correo', color:'error' },
	        	this.to=''
	        	this.subject=''
	        	this.cuerpo=''
	        }  
          var me = this
          // Mandar a login
          // setTimeout(function(){ me.$router.push({name: 'Login'}) }, 3000);
	      })

	  	},
    }
  };


</script>
